






import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import orderActionScenarios from "@/constants/orderActionScenarios";
import orderLocationStatuses from "@/constants/orderLocationStatuses";
import orderTelesaleStatuses from "@/constants/orderTelesaleStatuses";
import orderStatuses from "@/constants/orderStatuses";

function itemDownloadButton(index) {
  return {
    attrs: {},
    content: {
      icon: "mdi-download",
    },
    on: {
      xClick({ self }) {
        const { item } = self.context();
        console.log(item);
        coreApiClient.download("actionLogs", `${item._id}/files/${item.fileItems[index].uuid}`);
      },
    },
    target: {
      tooltip: {
        content: {
          text: `Download File ${+index + 1}`,
        },
      },
    },
    ext: {
      condition(_, item) {
        return item && item.fileItems && item.fileItems[index];
      },
    },
  };
}

export default Vue.extend({
  props: ["item"],
  data() {
    return {
      tableOptions: null,
    };
  },
  async created() {
    this.tableOptions = this.makeTableOptions(this.item);
  },
  methods: {
    makeTableOptions(item) {
      return {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
        },
        content: {
          urlEnabled: false,
          search: {
            ext: {
              hidden: true,
            },
          },
          topActionButtons: {
            showDisplaySetting: {
              ext: {
                hidden: true,
              },
            },
          },
          displayFields: {
            _id: {
              text: "ID",
              sortable: true,
            },
            createdTime: {
              text: "Thời gian tác động",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            user: {
              text: "Người dùng",
              sortable: true,
              options: {
                subProp: "user.name",
              },
            },
            actionType: {
              text: "Loại tác động",
              sortable: true,
              options: {
                label: true,
              },
            },
            actionScenario: {
              text: "Kịch bản tác động",
              sortable: true,
              options: {
                transform(value) {
                  const paths = orderActionScenarios[value];
                  if (!paths) {
                    return "N/A";
                  }
                  return paths.join(" - ");
                },
              },
            },
            before: {
              text: "Trạng thái trước",
              options: {
                transform(value, item) {
                  switch (item.actionScenario) {
                    case "LocationCheckItemUpdate":
                      return orderLocationStatuses[item.targetBefore.locationStatus];
                    case "TelesaleCheckItemUpdate":
                      return orderTelesaleStatuses[item.targetBefore.telesaleStatus];
                    default:
                      return orderStatuses[item.targetBefore && item.targetBefore.status];
                  }
                },
              },
            },
            after: {
              text: "Trạng thái sau",
              options: {
                transform(value, item) {
                  switch (item.actionScenario) {
                    case "LocationCheckItemUpdate":
                      return orderLocationStatuses[item.targetAfter.locationStatus];
                    case "TelesaleCheckItemUpdate":
                      return orderTelesaleStatuses[item.targetAfter.telesaleStatus];
                    default:
                      return orderStatuses[item.targetAfter && item.targetAfter.status];
                  }
                },
              },
            },
            actions: {},
          },
          template: {
            itemActionButtons: {
              download0: itemDownloadButton(0),
              download1: itemDownloadButton(1),
              download2: itemDownloadButton(2),
              download3: itemDownloadButton(3),
            },
          },
          findAllOptionsFilter(options) {
            options.filters.push({
              key: "targetType",
              operator: "equal_to",
              value: "Order",
            });
            options.filters.push({
              key: "targetIds",
              operator: "equal_to",
              value: item._id,
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("actionLogs", "findAll", options);
              return [items, count];
            },
          },
        },
      };
    },
  },
});
