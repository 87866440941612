export default {
    "kt": "Khởi tạo",
    "ssdt": "Sai số điện thoại",
    "sdttb": "Số điện thoại thuê bao",
    "kknm": "Khách không nghe máy",
    "k": "Khác",
    "ht": "Hoàn thành",
    "h": "Hủy"
}

