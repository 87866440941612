




























import Vue from "vue";

export default Vue.extend({
  props: ["item"],
  data() {
    return {
      tableOptions: null,
      displayFields: [],
    };
  },
  async created() {
    this.tableOptions = this.makeTableOptions(this.item);
    this.displayFields = this.makeDisplayFields(this.item);
  },
  methods: {
    makeTableOptions(item) {
      return {
        attrs: {},
        content: {
          urlEnabled: false,
          search: {
            ext: {
              hidden: true,
            },
          },
          topActionButtons: {
            showDisplaySetting: {
              ext: {
                hidden: true,
              },
            },
          },
          displayFields: {
            ...this.makeTableDisplayFields(item),
          },
        },
        ext: {
          dataProvider: {
            findAll: async () => {
              const items = this.makeTableData(item);
              return [items, items.length];
            },
          },
        },
      };
    },
    makeDisplayFields(item) {
      switch (item.mktPlatform?.code) {
        case "s":
          return [
            {
              text: "Original Price",
              value: item.shopeeEscrow?.original_price,
            },
            {
              text: "Deal Price",
              value: item.shopeeEscrow?.order_selling_price,
            },
            {
              text: "Product Subtotal",
              value: item.shopeeEscrow?.order_seller_discount,
            },
            {
              text: "Price Discount(from Seller)(PHP)",
              value: item.shopeeEscrow?.seller_discount,
            },
          ];
        case "lzd":
          return [
            {
              text: "Shipping Fee",
              value: item.orderRequest?.shipping_fee,
            },
          ];
        case "ttshop":
          return [
            {
              text: "SKU Subtotal Before Discount",
              value: item.orderRequest?.payment_info?.original_total_product_price,
            },
            {
              text: "SKU Platform Discount",
              value: item.orderRequest?.payment_info?.platform_discount,
            },
            {
              text: "SKU Seller Discount",
              value: item.orderRequest?.payment_info?.seller_discount,
            },
            {
              text: "SKU Subtotal After Discount",
              value: item.orderRequest?.payment_info?.sub_total,
            },
            {
              text: "Original Shipping Fee",
              value: item.orderRequest?.payment_info?.original_shipping_fee,
            },
            {
              text: "Shipping Fee Seller Discount",
              value: item.orderRequest?.payment_info?.shipping_fee_seller_discount,
            },
            {
              text: "Shipping Fee Platform Discount",
              value: item.orderRequest?.payment_info?.shipping_fee_platform_discount,
            },
            {
              text: "Taxes",
              value: item.orderRequest?.payment_info?.taxes,
            },
            {
              text: "Order Amount",
              value: item.orderRequest?.payment_info?.total_amount,
            },
          ];
      }
      return [];
    },
    makeTableData(item) {
      switch (item.mktPlatform?.code) {
        case "s":
          return item.shopeeEscrow?.items || [];
        case "lzd":
          return item.orderRequest?.items || [];
        case "ttshop":
          return item.orderRequest?.item_list || [];
      }
      return [];
    },
    makeTableDisplayFields(item) {
      switch (item.mktPlatform?.code) {
        case "s":
          return {
            model_sku: {
              text: "SKU",
            },
            quantity_purchased: {
              text: "Count",
            },
            original_price: {
              text: "Original Price",
            },
            seller_discount: {
              text: "Price Discount(from Seller)(PHP)",
            },
            weight: {
              text: "SKU Total Weight",
              options: {
                preTransform(_, { item_id: itemId }) {
                  return item.orderRequest?.item_list?.find((item) => item.item_id === itemId)?.weight;
                },
              },
            },
          };
        case "lzd":
          return {
            sku: {
              text: "SKU",
            },
            paid_price: {
              text: "Paid Price",
            },
            item_price: {
              text: "Unit Price",
            },
            voucher_amount: {
              text: "Seller Discount Total",
            },
          };
        case "ttshop":
          return {
            seller_sku: {
              text: "SKU",
            },
            quantity: {
              text: "Count",
            },
            sku_original_price: {
              text: "SKU Unit Original Price",
            },
          };
      }
      return {};
    },
  },
});
