






import orderShippingStatuses from "@/constants/orderShippingStatuses";
import Vue from "vue";

export default Vue.extend({
  props: ["item"],
  data() {
    return {
      self: this,
      tableOptions: null,
    };
  },
  methods: {
    makeTableOptions() {
      const self = this;
      return {
        attrs: {
          "item-key": "itemId",
        },
        content: {
          name: "Sản phẩm",
          search: {
            ext: {
              hidden: true,
            },
          },
          displayFields: {
            sku: {
              text: "SKU",
              options: {
                preTransform(_, item) {
                  return item.product?.sku || item.productCombo?.name;
                },
              },
            },
            count: {
              text: "Số lượng",
            },
            transportTrackingNumber: {
              text: "Tracking Number",
            },
            dxkTime: {
              text: "Ngày xuất kho",
              options: {
                filter: "dateTime",
              },
            },
            shippingStatus: {
              text: "TT Giao hàng",
              sortable: true,
              options: {
                label(value) {
                  return orderShippingStatuses[value || ""];
                },
              },
            },
            returned: {
              text: "Return đã về kho",
              options: {
                boolean: true,
              },
            },
            returnTime: {
              text: "Ngày Return",
              options: {
                filter: "dateTime",
              },
            },
          },
        },
        ext: {
          dataProvider: {
            async findAll() {
              console.log(self.item);
              return [self.item.productRowItems || [], self.item.productRowItems?.length || 0];
            },
          },
        },
      };
    },
  },
  async created() {
    this.tableOptions = this.makeTableOptions();
  },
});
